.section {
  padding-top: 4rem;
  h1, h2, h3, h4, h5, h6 {
    @apply text-black;
  }
  .paragraph {
    @apply text-black-1;
    padding-top: 2rem;
  }
}
